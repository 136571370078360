<template>
    <div>
        <v-row>
            <v-col cols="12" class="py-0">
                <h2 class="mb-2">{{ title }}</h2>
                <p>{{ subtitle }}</p>
            </v-col>
        </v-row> 
    </div>
</template>

<script>

export default {
    name: 'HeaderTitle',

    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String
        }
    }

}

</script>

<style lang="scss" scoped>

    @import './src/design/variables.scss';

    h2 {
        color: (--v-titleColor);
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
    }
    p {
        color: $neutral-color-high-medium;
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
    }
</style>