var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
            _c("h2", { staticClass: "mb-2" }, [_vm._v(_vm._s(_vm.title))]),
            _c("p", [_vm._v(_vm._s(_vm.subtitle))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }